@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    width: auto;
    height: auto;
  }
}
