@import "tailwind.css";
@import "mui.scss";
@import "mui/button.scss";
@import "mui/textfield.scss";

@media print {
  .print-bar {
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.prevent-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pattern-diagonalv3 {
  background-color: #00000000;
  background-size: 10px 10px;
  background-image: repeating-linear-gradient(
    45deg,
    #00000020 0,
    #00000020 1px,
    #00000000 0,
    #00000000 50%
  );
}

@font-face {
  font-family: "pretendard";
  src: url("/static/fonts/woff-subset/Pretendard-Regular.subset.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

pre {
  font-family: "pretendard" !important;
  line-height: 1.7 !important;
}
